<template>
  <div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-start justify-content-end py-1">
        <b-col md="12" class="px-3 py-1">
          <h3 class="font-weight-bolder text-colorBlack">Math</h3>
          <p class="font-weight-bold text-colorGray mb-50">Code4545</p>
        </b-col>
      </b-row>
      <b-row class="px-2 w-100">
        <b-col md="12">
          <b-tabs content-class="mt-50">
            <b-tab class="pl-1" active>
              <template #title>
                <feather-icon icon="SearchIcon" />
                <span class="font-weight-bolder text-colorBlack">Lectures</span>
              </template>
              <b-row class="d-flex align-items-center justify-content-start">
                <b-col md="8" class="py-50">
                  <h3 class="font-weight-bolder text-colorBlack m-0">
                    Lectures
                  </h3>
                </b-col>
                <b-col md="4" class="py-50">
                  <div class="pb-1 pt-1 text-right">
                    <b-button
                      size="md"
                      type="submit"
                      variant="colorBlue"
                      @click="
                        () => {
                          this.$router.push({
                            name: 'InstructorLectureCreate',
                          });
                        }
                      "
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="font-weight-bolder text-colorWhite"
                      />
                      <span class="font-weight-bolder text-colorWhite">
                        New Lecture</span
                      >
                    </b-button>
                  </div>
                </b-col>
              </b-row>

              <b-card class="mx-2" style="border-radius: 20px">
                <b-table
                  stacked="md"
                  :fields="fieldsLecture"
                  :items="Lecture"
                  thead-class="tableHeadColor"
                  small
                  rounded
                >
                  <template #head()="data">
                    <div class="py-50">
                      <span class="font-weight-bolder text-colorBlack">{{
                        data.label
                      }}</span>
                    </div>
                  </template>

                  <template #cell(Assignmnet)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.Assignmnet ? row.item.Assignmnet : "" }}
                        <br />
                        <span class="font-weight-bold text-colorGray">
                          {{ row.item.DateTime ? row.item.DateTime : "" }}</span
                        >
                      </span>
                    </div>
                  </template>
                  <template #cell(DateTime)="row">
                    <span
                      class="font-weight-bolder text-colorBlack m-0"
                      style="font-size: 0.8rem"
                    >
                      {{ row.item.DateTime ? row.item.DateTime : "" }}
                    </span>
                  </template>

                  <template #cell(StudentView)="row">
                    <div class="py-1">
                      <span></span>
                      <b-progress
                        class="mt-1"
                        :value="row.item.StudentView"
                        :max="max"
                        show-progress
                        animated
                      ></b-progress>
                      <span
                        class="font-weight-bold text-colorBlack"
                        style="font-size: 0.8rem"
                        >{{
                          row.item.StudentView ? row.item.StudentView : ""
                        }}
                        / 20</span
                      >
                    </div>
                  </template>

                  <template #cell(Subject)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.Subject ? row.item.Subject : "" }}
                      </span>
                    </div>
                  </template>
                  <template #cell(Class)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.Class ? row.item.Class : "" }}
                      </span>
                    </div>
                  </template>

                  <template #cell(LectureDuartion)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{
                          row.item.LectureDuartion
                            ? row.item.LectureDuartion
                            : ""
                        }}
                      </span>
                    </div>
                  </template>

                  <template #cell(manage)="">
                    <img
                      src="@/assets/images/icons/instructor/assignment/Delete.svg"
                      img-fluid
                      class="mx-1 cursor-pointer"
                      width="30px"
                      height="30px"
                    />
                    <img
                      src="@/assets/images/icons/instructor/assignment/Edit.svg"
                      img-fluid
                      width="30px"
                      height="28px"
                      class="cursor-pointer"
                    />
                  </template>
                </b-table>
              </b-card>
            </b-tab>
            <b-tab class="pl-1">
              <template #title>
                <feather-icon icon="SearchIcon" />
                <span class="font-weight-bolder text-colorBlack"
                  >Assignments</span
                >
              </template>
              <b-row class="d-flex align-items-center justify-content-start">
                <b-col md="8" class="py-50">
                  <h3 class="font-weight-bolder text-colorBlack m-0">
                    Assignments
                  </h3>
                </b-col>
                <b-col md="4" class="py-50">
                  <div class="pb-1 pt-1 text-right">
                    <b-button
                      size="md"
                      type="submit"
                      variant="colorBlue"
                      @click="
                        () => {
                          this.$router.push({
                            name: 'InstructorAssignmentCreate',
                          });
                        }
                      "
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="font-weight-bolder text-colorWhite"
                      />
                      <span class="font-weight-bolder text-colorWhite">
                        New Assignment</span
                      >
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-card class="mx-2" style="border-radius: 20px">
                <b-table
                  stacked="md"
                  :fields="fieldsAssignment"
                  :items="Assignment"
                  thead-class="tableHeadColor"
                  small
                  rounded
                >
                  <template #head()="data">
                    <div class="py-50">
                      <span class="font-weight-bolder text-colorBlack">{{
                        data.label
                      }}</span>
                    </div>
                  </template>

                  <template #cell(Assignmnet)="row">
                    <div class="py-3">
                      <b-link :to="{ name: 'InstructorAssignmentDetail' }">
                        <span
                          class="font-weight-bolder text-colorBlack m-0"
                          style="font-size: 0.8rem"
                        >
                          {{ row.item.Assignmnet ? row.item.Assignmnet : "" }}
                          <br />
                          <span class="font-weight-bold text-colorGray">
                            {{
                              row.item.DateTime ? row.item.DateTime : ""
                            }}</span
                          >
                        </span>
                      </b-link>
                    </div>
                  </template>
                  <template #cell(Deadline)="row">
                    <span
                      class="font-weight-bolder text-colorBlack m-0"
                      style="font-size: 0.8rem"
                    >
                      {{ row.item.Deadline ? row.item.Deadline : "" }}
                    </span>
                  </template>

                  <template #cell(SubmissionStatus)="row">
                    <div class="py-1">
                      <span></span>
                      <b-progress
                        class="mt-1"
                        :value="row.item.SubmissionStatus"
                        :max="max"
                        show-progress
                        animated
                      ></b-progress>
                      <span
                        class="font-weight-bold text-colorBlack"
                        style="font-size: 0.8rem"
                        >{{
                          row.item.SubmissionStatus
                            ? row.item.SubmissionStatus
                            : ""
                        }}
                        / 20</span
                      >
                    </div>
                  </template>

                  <template #cell(Subject)="row">
                    <div class="py-3">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.Subject ? row.item.Subject : "" }}
                      </span>
                    </div>
                  </template>
                  <template #cell(Class)="row">
                    <div class="py-3">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.Class ? row.item.Class : "" }}
                      </span>
                    </div>
                  </template>
                  <template #cell(GradingStatus)="row">
                    <div>
                      <span></span>
                      <b-progress
                        class="mt-1"
                        :value="row.item.GradingStatus"
                        :max="max"
                        show-progress
                        animated
                      ></b-progress>
                      <span
                        class="font-weight-bold text-colorBlack"
                        style="font-size: 0.8rem"
                        >{{
                          row.item.GradingStatus ? row.item.GradingStatus : ""
                        }}
                        / 20</span
                      >
                    </div>
                  </template>
                  <template #cell(manage)="">
                    <img
                      src="@/assets/images/icons/instructor/assignment/Delete.svg"
                      img-fluid
                      class="mx-1 cursor-pointer"
                      width="30px"
                      height="30px"
                    />
                    <img
                      src="@/assets/images/icons/instructor/assignment/Edit.svg"
                      img-fluid
                      width="30px"
                      height="28px"
                      class="cursor-pointer"
                    />
                  </template>
                </b-table>
              </b-card>
            </b-tab>
            <b-tab class="pl-1">
              <template #title>
                <feather-icon icon="SearchIcon" />
                <span class="font-weight-bolder text-colorBlack"
                  >Study Material
                </span>
              </template>
              <b-row class="d-flex align-items-center justify-content-start">
                <b-col md="8" class="py-50">
                  <h3 class="font-weight-bolder text-colorBlack m-0">
                    Study Material
                  </h3>
                </b-col>
                <b-col md="4" class="py-50">
                  <div class="pb-1 pt-1 text-right">
                    <b-button
                      size="md"
                      type="submit"
                      variant="colorBlue"
                      @click="openModalUploadMaterial"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="font-weight-bolder text-colorWhite"
                      />
                      <span class="font-weight-bolder text-colorWhite">
                        Upload Material</span
                      >
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-card class="mt-2" style="border-radius: 20px">
                <b-table
                  stacked="md"
                  :fields="fieldsStudyMaterial"
                  :items="StudyMaterial"
                  details-td-class="m-0 p-0"
                  thead-class="tableHeadColor"
                  small
                  rounded
                >
                  <template #head()="data">
                    <div class="py-50">
                      <span class="font-weight-bolder text-colorBlack">{{
                        data.label
                      }}</span>
                    </div>
                  </template>

                  <template #cell(Lecture)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        <img
                          src="@/assets/images/icons/assignment/pdf.svg"
                          img-fluid
                          width="40px"
                          height="40px"
                          class="mr-50"
                        />
                        {{ row.item.Lecture ? row.item.Lecture : "" }}
                      </span>
                    </div>
                  </template>
                  <template #cell(DateTime)="row">
                    <span
                      class="font-weight-bolder text-colorBlack m-0"
                      style="font-size: 0.8rem"
                    >
                      {{ row.item.DateTime ? row.item.DateTime : "" }}
                    </span>
                  </template>

                  <template #cell(manage)="">
                    <img
                      src="@/assets/images/icons/instructor/assignment/Delete.svg"
                      img-fluid
                      class="mx-1 cursor-pointer"
                      width="30px"
                      height="30px"
                    />
                    <img
                      src="@/assets/images/icons/instructor/assignment/Edit.svg"
                      img-fluid
                      width="30px"
                      height="28px"
                      class="cursor-pointer"
                    />
                  </template>
                </b-table>
              </b-card>
              <b-card class="mt-2" style="border-radius: 20px">
                <b-table
                  stacked="md"
                  :fields="fieldsStudyMaterial2"
                  :items="StudyMaterial2"
                  details-td-class="m-0 p-0"
                  thead-class="tableHeadColor"
                  small
                  rounded
                >
                  <template #head()="data">
                    <div class="py-50">
                      <span class="font-weight-bolder text-colorBlack">{{
                        data.label
                      }}</span>
                    </div>
                  </template>

                  <template #cell(Lecture)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        <img
                          src="@/assets/images/icons/assignment/pdf.svg"
                          img-fluid
                          width="40px"
                          height="40px"
                          class="mr-50"
                        />
                        {{ row.item.Lecture ? row.item.Lecture : "" }}
                      </span>
                    </div>
                  </template>
                  <template #cell(DateTime)="row">
                    <span
                      class="font-weight-bolder text-colorBlack m-0"
                      style="font-size: 0.8rem"
                    >
                      {{ row.item.DateTime ? row.item.DateTime : "" }}
                    </span>
                  </template>

                  <template #cell(manage)="">
                    <b-button
                      variant="colorBlue"
                      rounded
                      size="sm"
                      class="text-center ml-0 ml-md-4"
                    >
                      <feather-icon icon="UploadIcon" class="mr-50" />
                      Upload File
                    </b-button>
                  </template>
                </b-table>
              </b-card>
            </b-tab>
            <b-tab class="pl-1">
              <template #title>
                <feather-icon icon="SearchIcon" />
                <span class="font-weight-bolder text-colorBlack"
                  >Description
                </span>
              </template>
              <b-row class="d-flex align-items-center justify-content-start">
                <b-col md="8" class="py-50">
                  <h3 class="font-weight-bolder text-colorBlack m-0">
                    Description
                  </h3>
                </b-col>
                <b-col md="4" class="py-50">
                  <div class="pb-1 pt-1 text-right">
                    <b-button
                      v-if="!isEditDescription"
                      size="md"
                      type="submit"
                      variant="colorBlue"
                      @click="toggleEditDescription"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="font-weight-bolder text-colorWhite mr-50"
                      />
                      <span class="font-weight-bolder text-colorWhite">
                        Edit</span
                      >
                    </b-button>
                    <b-button
                      v-if="isEditDescription"
                      size="md"
                      type="submit"
                      variant="colorBlue"
                      @click="toggleEditDescriptionAgain"
                    >
                      <feather-icon
                        icon="UploadIcon"
                        class="font-weight-bolder text-colorWhite mr-50"
                      />
                      <span class="font-weight-bolder text-colorWhite">
                        Upload</span
                      >
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-card
                class="mt-2 bg-colorBgGray"
                v-if="!isEditDescription"
                style="border-radius: 20px"
              >
                <p class="font-weight-bold text-colorBlack px-1">
                  {{ this.content }}
                </p>
              </b-card>
              <b-card
                class="mt-2 bg-colorBgGray"
                v-if="isEditDescription"
                style="border-radius: 20px"
              >
                <b-form-group class="mt-2">
                  <quill-editor v-model="content" :options="option" />
                </b-form-group>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>
    <UploadMaterialModal
      :modalVisible="modalUploadMaterial"
      @close="closeModalUploadMaterial"
    />
  </div>
</template>

<script>
import UploadMaterialModal from "@/components/instructor/assignment/UploadMaterialModal.vue";
export default {
  components: {
    UploadMaterialModal,
  },
  data() {
    return {
      content:
        "Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum is simply dummy text of the printing andLorem Ipsum is simply dummy text of the printing andLorem Ipsum is simply dummy text of the printing andLorem Ipsum is simply dummy text of the printing andLorem Ipsum is simply dummy text of the printing andLorem Ipsum is simply dummy text of the printing andLorem Ipsum is simply dummy text of the printing andLorem Ipsum is simply dummy text of the printing andLorem Ipsum is simply dummy text of the printing and",
      option: {},
      isEditDescription: false,
      modalUploadMaterial: false,
      fieldsLecture: [
        { key: "Assignmnet", label: "Assignmnet" },
        { key: "DateTime", label: "Date & Time" },
        { key: "StudentView", label: "Student View" },
        { key: "Subject", label: "Subject " },
        { key: "Class", label: "Class " },
        { key: "LectureDuartion", label: "Lecture Duartion" },
        { key: "manage", label: "" },
      ],
      Lecture: [
        {
          Assignmnet: " Introduction to Data Science & ML",
          DateTime: "24 Sep, 2020 02:00 pm",
          StudentView: "15",
          Subject: "Math",
          Class: "10A",
          LectureDuartion: "20 Minutes",
        },
        {
          Assignmnet: " Introduction to Data Science & ML",
          DateTime: "24 Sep, 2020 02:00 pm",
          StudentView: "15",
          Subject: "Math",
          Class: "10A",
          LectureDuartion: "20 Minutes",
        },
        {
          Assignmnet: " Introduction to Data Science & ML",
          DateTime: "24 Sep, 2020 02:00 pm",
          StudentView: "15",
          Subject: "Math",
          Class: "10A",
          LectureDuartion: "20 Minutes",
        },
        {
          Assignmnet: " Introduction to Data Science & ML",
          DateTime: "24 Sep, 2020 02:00 pm",
          StudentView: "15",
          Subject: "Math",
          Class: "10A",
          LectureDuartion: "20 Minutes",
        },
      ],
      fieldsAssignment: [
        { key: "Assignmnet", label: "Assignmnet" },
        { key: "Deadline", label: "Deadline" },
        { key: "SubmissionStatus", label: "Submission Status" },
        { key: "Subject", label: "Subject " },
        { key: "Class", label: "Class " },
        { key: "GradingStatus", label: "Grading Status" },
        { key: "manage", label: "" },
      ],
      Assignment: [
        {
          Assignmnet: " Introduction to Data Science & ML",
          Deadline: "24 Sep, 2020 02:00 pm",
          SubmissionStatus: "15",
          Subject: "Math",
          Class: "10A",
          GradingStatus: "18",
        },
        {
          Assignmnet: " Introduction to Data Science & ML",
          Deadline: "24 Sep, 2020 02:00 pm",
          SubmissionStatus: "15",
          Subject: "Math",
          Class: "10A",
          GradingStatus: "18",
        },
        {
          Assignmnet: " Introduction to Data Science & ML",
          Deadline: "24 Sep, 2020 02:00 pm",
          SubmissionStatus: "15",
          Subject: "Math",
          Class: "10A",
          GradingStatus: "18",
        },
        {
          Assignmnet: " Introduction to Data Science & ML",
          Deadline: "24 Sep, 2020 02:00 pm",
          SubmissionStatus: "15",
          Subject: "Math",
          Class: "10A",
          GradingStatus: "18",
        },
      ],
      fieldsStudyMaterial: [
        { key: "Lecture", label: "Lecture 1" },
        { key: "DateTime", label: "Date & Time" },
        { key: "manage", label: "" },
      ],
      StudyMaterial: [
        {
          Lecture: " Data Parsing.mp4",
          DateTime: "24 Sep, 2020 02:00 pm - 03:00 pm",
        },
        {
          Lecture: " Intro to Data Parsing.pdf",
          DateTime: "24 Sep, 2020 02:00 pm - 03:00 pm",
        },
        {
          Lecture: " Intro to Data Parsing.pdf",
          DateTime: "24 Sep, 2020 02:00 pm - 03:00 pm",
        },
        {
          Lecture: " Intro to Data Parsing.pdf",
          DateTime: "24 Sep, 2020 02:00 pm - 03:00 pm",
        },
      ],
      fieldsStudyMaterial2: [
        { key: "Lecture", label: "Lecture 2" },
        { key: "DateTime", label: "Date & Time" },
        { key: "manage", label: "" },
      ],
      StudyMaterial2: [
        {
          Lecture: " Data Parsing.mp4",
          DateTime: "24 Sep, 2020 02:00 pm - 03:00 pm",
        },
        {
          Lecture: " Intro to Data Parsing.pdf",
          DateTime: "24 Sep, 2020 02:00 pm - 03:00 pm",
        },
        {
          Lecture: " Intro to Data Parsing.pdf",
          DateTime: "24 Sep, 2020 02:00 pm - 03:00 pm",
        },
        {
          Lecture: " Intro to Data Parsing.pdf",
          DateTime: "24 Sep, 2020 02:00 pm - 03:00 pm",
        },
      ],
      subjectCode: "",
      value: 20,
      max: 100,
    };
  },
  methods: {
    openModalUploadMaterial() {
      this.modalUploadMaterial = true;
    },
    closeModalUploadMaterial() {
      this.modalUploadMaterial = false;
    },
    toggleEditDescription() {
      this.isEditDescription = true;
    },
    toggleEditDescriptionAgain() {
      this.isEditDescription = false;
    },
  },
};
</script>

<style>
.tableHeadColor,
.table thead th,
thead,
th {
  background-color: #ebf0fc !important;
}
</style>
